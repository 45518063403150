import React from 'react';
import header from "../assets/tussenstopHeader.jpg";
import "../styles/prijzen.css"
import { mdiFaceWoman, mdiFaceMan, mdiTeddyBear} from '@mdi/js';
import Icon from '@mdi/react';

function Prijzen() {
    return (
        <div>
            <div className={"imageContainer"}>
                <img className={"headerImage"} src={header} alt={"whiteLogo"}/>
                <div className="text-overlay">
                    <h1 className={"naamKapsalon"}>Prijzen</h1>
                    <h5><i>Bij de tussenstop zijn alle prijzen inclusief BTW en stylingproducten.
                        Je kunt bij ons pinnen.</i></h5>
                </div>
            </div>

            <div className={"prijzenlijstContainer"}>
                <div className={"vrouwenContainer"}>
                <h2 className={"header"}>
                    <Icon path={mdiFaceWoman} size={3} className="icon" />
                    Vrouwen
                </h2>
                <li>
                    <ul className={"prijzen"}>
                        <p className={"naam"}>Wassen/knippen/drogen</p>
                        <p className={"prijs"}>€37,50</p>
                    </ul>
                    <ul className={"prijzen"}>
                        <p className={"naam"}>Wassen/knippen/föhnen</p>
                        <p className={"prijs"}>€47,50</p>
                    </ul>
                    <ul className={"prijzen"}>
                        <p className={"naam"}>Wassen/föhnen</p>
                        <p className={"prijs"}>€32,50</p>
                    </ul>
                </li>
                </div>

                <div className={"mannenContainer"}>
                    <h2 className={"mannenText"}>
                        <Icon className={"mannenIcon"} path={mdiFaceMan} size={3}/>
                        Mannen
                    </h2>
                    <li>
                        <ul className={"prijzen"}>
                            <p className={"naam"}>Wassen/knippen/drogen</p>
                            <p className={"prijs"}>€35,00</p>
                        </ul>
                        <ul className={"prijzen"}>
                            <p className={"naam"}>Tondeuze</p>
                            <p className={"prijs"}>€25,00</p>
                        </ul>
                    </li>
                </div>

                <div className={"kinderContainer"}>
                    <h2 className={"kinderText"}>
                        <Icon path={mdiTeddyBear} size={3} className="kinderIcon" />
                        Kinderen
                    </h2>
                    <li>
                        <ul className={"prijzen"}>
                            <p className={"naam"}>Knippen t/m 12</p>
                            <p className={"prijs"}>€24,50</p>
                        </ul>
                        <ul className={"prijzen"}>
                            <p className={"naam"}>Knippen 13 t/m 17</p>
                            <p className={"prijs"}>€29,50</p>
                        </ul>
                    </li>
                </div>
            </div>

            <div className={"lowerContainer"}>
                <div className={"kleurenContainer"}>
                    <h2 className={"kleurenText"}>
                        Specials
                    </h2>
                    <li>
                        <ul className={"prijzen"}>
                            <p className={"naam"}>Kleuren</p>
                            <p className={"prijs"}>€47,50</p>
                        </ul>
                        <ul className={"prijzen"}>
                            <p className={"naam"}>Kleuren op natuurbasis</p>
                            <p className={"prijs"}>€52,50</p>
                        </ul>
                        <ul className={"prijzen"}>
                            <p className={"naam"}>Extra kleur</p>
                            <p className={"prijs"}>€19,50</p>
                        </ul>
                        <br/>
                        <ul className={"prijzen"}>
                            <p className={"naam"}>Highlights vanaf:</p>
                            <p className={"prijs"}>€47,50</p>
                        </ul>
                        <ul className={"prijzen"}>
                            <p className={"naam"}>Extra spoeling bij highlights</p>
                            <p className={"prijs"}>€22,50</p>
                        </ul>
                        <br/>
                        <ul className={"prijzen"}>
                            <p className={"naam"}>Permanent all-in</p>
                            <p className={"prijs"}>€99,50</p>
                        </ul>
                        <b style={{ justifyContent: 'center', display: 'flex', marginBottom: '10px'}}>Bruidskapsels in overleg</b>
                    </li>
                </div>


            </div>
        </div>
    );
}

export default Prijzen;
