import React from 'react';
import header from "../assets/tussenstopHeader.jpg";
import "../styles/nieuws.css"
import kapsalon from "../assets/foto news.jpeg"
function Nieuws() {
    return (
        <div>
            <div className={"imageContainer"}>
                <img className={"headerImage"} src={header} alt={"whiteLogo"}/>
                <div className="text-overlay">
                    <h2>Nieuws</h2>
                </div>
            </div>
            <div>
                {/*Geen nieuws op het moment.*/}
                <div className="listContainer">
                    <div className="list-item">
                        <div className={"border"}>
                            <img className={"imgClass"} src={kapsalon} alt={"plaatje kapsalon"}/>
                            <h1>Afspraken kerst</h1>
                            <p>Maak alvast een afspraak voor de kerst. Voor je het weet is het al zover. 😉</p>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    );
}

export default Nieuws;
